<template>
  <CyHeader :loading="header.loading">
    <template
      v-if="header.avatar"
      #avatar>
      <CyAvatar
        v-if="header.avatar.type === 'avatar'"
        :item="header.avatar.item"/>
      <CyIconCredential
        v-else-if="header.avatar.type === 'providerIcon'"
        size="30"
        class="mr-4"
        :type="header.avatar.item"/>
      <CyAvatar
        v-else-if="header.avatar.type === 'environmentIcon'"
        :item="header.avatar.item"
        icon-size="24"
        class="mr-1"
        md/>
    </template>
    <template #title>
      <div
        v-if="header.icon"
        data-cy="header-icon"
        class="d-flex mr-2"
        v-html="$sanitizeHtml(header.icon)"/>
      {{ header.title }}
      <CyTag
        v-if="header.tag"
        variant="default"
        class="ml-4">
        {{ header.tag }}
      </CyTag>
    </template>
    <template #description>
      <div v-if="header.description">
        {{ header.description.text }}
        <a
          v-if="header.description.link"
          :href="header.description.link"
          target="_blank"
          rel="noopener noreferrer"
          class="cy-link"
          v-text="$t('learnMore')"/>
      </div>
    </template>
    <template #actions>
      <portal-target name="header-actions"/>
      <CyButton
        v-if="header.action"
        :icon="header.action.icon"
        variant="secondary"
        theme="primary"
        :to="header.action.to">
        {{ header.action.text }}
      </CyButton>
    </template>
    <template #metadata>
      <CyHeaderMetadata
        v-if="header.owner"
        :label="displayName(header.owner)">
        <CyAvatar
          xs
          :item="header.owner"/>
      </CyHeaderMetadata>
      <CyHeaderMetadata v-if="header.credential">
        <CyIconCredential
          :key="header.credential"
          :type="header.credential"
          size="24"
          disabled/>
        {{ header.credential }}
      </CyHeaderMetadata>
      <CyHeaderMetadata
        v-for="meta in header.metas"
        :key="meta"
        :label="meta"/>
      <CyHeaderMetadata
        v-if="header.createdAt"
        :label="$t('created', [$date.format($date.$getTimestamp(header.createdAt), 'MMM d, yyyy')])"/>
      <CyHeaderMetadata
        v-if="header.updatedAt"
        :label="$t('updated', [$date.$formatTimeAgo(header.updatedAt)])"/>
    </template>
  </CyHeader>
</template>

<script>
import CyHeader from '@/components/header.vue'
import CyHeaderMetadata from '@/components/header/metadata.vue'
import { displayName } from '@/utils/helpers'
import { mapState } from 'vuex'

export default {
  name: 'CyHeaderVariantGeneric',
  components: {
    CyHeader,
    CyHeaderMetadata,
  },
  computed: {
    ...mapState('layout', [
      'header',
    ]),
  },
  methods: {
    displayName,
  },
  i18n: {
    messages: {
      en: {
        updated: 'Updated {0}',
        created: 'Created on {0}',
      },
      es: {
        updated: 'Actualizado {0}',
        created: 'Creado el {0}',
      },
      fr: {
        updated: 'Mis à jour {0}',
        created: 'Créé le {0}',
      },
    },
  },
}
</script>
