<template>
  <ul class="secondary-nav space-x-2 pl-2">
    <li class="secondary-nav__item">
      <CySecondaryNavHelpMenu/>
    </li>
    <li class="secondary-nav__item">
      <CySecondaryNavUserMenu/>
    </li>
  </ul>
</template>

<script>
import CySecondaryNavHelpMenu from '@/components/secondary-nav/help-menu'
import CySecondaryNavUserMenu from '@/components/secondary-nav/user-menu'

export default {
  name: 'CySecondaryNav',
  components: {
    CySecondaryNavHelpMenu,
    CySecondaryNavUserMenu,
  },
}
</script>

<style lang="scss" scoped>
$nav-item-size: 32px;

.secondary-nav {
  display: flex;
  align-items: center;
  list-style: none;

  &__item {
    display: flex;
    align-items: center;
    height: $nav-item-size;
  }
}
</style>
