<template>
  <CyMenu
    bottom
    left
    offset-y
    transition="slide-y-transition"
    origin="top right"
    content-class="mt-2 py-2 cy-menu secondary-nav__menu cy-menu-help help-menu no-active-indicators"
    nudge-bottom="10"
    nudge-right="10"
    role="help-menu">
    <template #activator="{ on }">
      <span>
        <CyButton
          variant="tertiary"
          theme="primary"
          icon="help_outline"
          icon-only
          sm
          v-on="on"
          @click="$gtm.trackEvent($static.gtmHeaderEvents.headNewReleasesHelpDropDown)"/>
      </span>
      <span
        v-if="servicesStatus.status === 'Error'"
        class="cy-menu__red-dot"/>
    </template>

    <v-list
      dense
      class="py-0">
      <template v-for="({ key, icon, text, action = () => {}, to, cssClass = '', color }) in helpItems">
        <v-list-item
          v-if="key !== 'divider'"
          :key="`${key}-${color}-listItem`"
          v-hover-siblings
          :class="[cssClass, 'cy-menu__item']"
          :to="to"
          @click="action">
          <v-list-item-action>
            <v-icon
              :color="color || 'primary'"
              size="22">
              {{ icon }}
            </v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title
              :class="{
                'v-list-item__title--error': key === 'status' && servicesStatus.status === 'Error',
              }">
              {{ text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider
          v-else
          :key="`${key}-${color}-divider`"
          class="my-2"/>
      </template>
    </v-list>

    <div
      class="bottom-links"
      @click.stop>
      <span>{{ appVersion }}</span><br>
      <span v-if="apiVersion">API {{ apiVersion.version }}</span>
    </div>
  </CyMenu>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { gtmHeaderEvents } from '@/utils/helpers/analytics'
import { getStatusIcon } from '@/utils/helpers'
import { getFigmaLink } from '@/utils/config/figmaLinks'

export default {
  name: 'CySecondaryNavHelpMenu',
  data: () => ({
    appVersion: process.env.VUE_APP_VERSION,
  }),
  computed: {
    ...mapState({
      apiVersion: (state) => state.apiVersion,
      servicesStatus: (state) => state.servicesStatus,
    }),
    ...mapGetters([
      'hasActiveBillingPlan',
      'isAuthenticated',
    ]),
    ...mapGetters('user', [
      'isTourInProgress',
    ]),
    $static: () => ({
      gtmHeaderEvents,
    }),
    helpItems () {
      if (!this.isAuthenticated) return []

      const devLinkIcon = 'launch'
      const divider = [{ key: 'divider' }]
      const usingProdData = _.isEqual(process.env.VUE_APP_API_URL, 'https://http-api.cycloid.io')
      const productDocumentation = [{
        key: 'productDocumentation',
        icon: 'menu_book',
        text: this.$t('productDocumentation'),
        action: () => {
          window.open(this.$docsUrl, '_blank')
          this.$gtm.trackEvent(this.$static.gtmHeaderEvents.headNewReleasesDocumentation)
        },
      }]
      const apiDocumentation = [{
        key: 'apiDocumentation',
        icon: 'data_object',
        text: this.$t('apiDocumentation'),
        action: () => {
          window.open(this.$docLinks.api.index, '_blank')
          this.$gtm.trackEvent(this.$static.gtmHeaderEvents.headNewReleasesDocumentation)
        },
      }]
      const privacyPolicy = [{
        key: 'privacyPolicy',
        icon: 'policy',
        text: this.$t('routes.privacy'),
        action: () => window.open(this.$router.resolve({ name: 'privacy' }).href, '_blank'),
      }]
      const roadmap = [{
        key: 'roadmap',
        icon: 'map',
        text: this.$t('roadmap'),
        action: () => {
          window.open('https://roadmap.cycloid.io', '_blank')
          this.$gtm.trackEvent(this.$static.gtmHeaderEvents.headRoadmap)
        },
      }]
      const releaseNotes = [{
        key: 'releaseNotes',
        icon: 'auto_awesome',
        text: this.$t('releaseNotes'),
        action: () => {
          window.open(process.env.VUE_APP_RELEASE_NOTES_URL, '_blank')
          this.$gtm.trackEvent(this.$static.gtmHeaderEvents.headNewReleasesSeeAll)
        },
      }]
      const status = this.servicesStatus.status === 'Error' ? 'Error' : null
      const serviceStatus = [{
        key: 'status',
        icon: getStatusIcon(status).icon,
        color: getStatusIcon(status).color,
        text: this.$t('status'),
        to: { name: 'status' },
      }]
      const launchTutorial = this.hasActiveBillingPlan && [{
        key: 'launchTutorial',
        icon: 'tour',
        text: this.isTourInProgress ? this.$t('resetTutorial') : this.$t('launchTutorial'),
        action: this.RESET_TOUR,
      }]
      const devActionsEEnv = this.$isEEnv && [{
        key: 'linkToPR',
        icon: devLinkIcon,
        cssClass: 'rainbow-hover',
        text: `🦄 This e-env's PR`,
        action: () => window.open(`https://github.com/cycloidio/youdeploy-frontend-web/pull/${location.host.split('.')[0].slice(11)}`, '_blank'),
      }]
      const devActionsStaging = (this.$isDevMode || this.$isEEnv) && !usingProdData && [{
        key: 'linkToStaging',
        icon: devLinkIcon,
        cssClass: 'rainbow-hover',
        text: '🦄 This page on staging',
        action: () => window.open(`http://console.staging.cycloid.io${this.$route.path}`, '_blank'),
      }]
      const devActionsProd = this.$isDevMode && usingProdData && [{
        key: 'linkToBeta',
        icon: devLinkIcon,
        cssClass: 'rainbow-hover',
        text: '🦄 This page on beta',
        action: () => window.open(`http://console.beta.cycloid.io${this.$route.path}`, '_blank'),
      }, {
        key: 'linkToProd',
        icon: devLinkIcon,
        cssClass: 'rainbow-hover',
        text: '🦄 This page on prod',
        action: () => window.open(`http://console.cycloid.io${this.$route.path}`, '_blank'),
      }]
      const devActionsStagingDevOrEEnv = (this.$isStagingOrDev || this.$isEEnv) && [{
        key: 'linkToFigma',
        icon: devLinkIcon,
        cssClass: 'rainbow-hover',
        text: '🦄 Mockups on Figma',
        action: () => window.open(getFigmaLink(this.$route.meta.figmaKey), '_blank'),
      }, {
        key: 'sandbox',
        icon: devLinkIcon,
        cssClass: 'rainbow-hover',
        text: '🦄 Open sandbox',
        to: { name: 'sandbox' },
      }]
      const showDevThings = _.some([this.$isStagingOrDev, this.$isEEnv]) && [{
        key: 'toggleDevButtons',
        icon: this.$showDevThings ? 'visibility_off' : 'visibility',
        cssClass: 'rainbow-hover',
        text: this.$showDevThings ? '🦄 Hide dev things' : '🦄 Show dev things',
        action: this.TOGGLE_DEV_BTNS,
      }]
      return [
        ...productDocumentation,
        ...apiDocumentation,
        ...launchTutorial || [],
        ...privacyPolicy,
        ...divider,
        ...roadmap,
        ...releaseNotes,
        ...serviceStatus || [],
        ...(this.$showDevThings
          ? [
              ...devActionsEEnv || [],
              ...devActionsStaging || [],
              ...devActionsProd || [],
              ...devActionsStagingDevOrEEnv || [],
            ]
          : []
        ),
        ...showDevThings || [],
      ]
    },
  },
  async mounted () {
    await this.FETCH_API_VERSION()
  },
  methods: {
    ...mapActions([
      'FETCH_API_VERSION',
    ]),
    ...mapActions('user', [
      'RESET_TOUR',
    ]),
    ...mapMutations('dev', [
      'TOGGLE_DEV_BTNS',
    ]),
  },
  i18n: {
    messages: {
      en: {
        apiDocumentation: 'API documentation',
        productDocumentation: 'Product documentation',
        needHelp: 'Need help',
        seeAllReleases: 'See all releases',
        stackcraftStepper: 'Launch StackCraft presentation',
        launchTutorial: 'Launch tour',
        releaseNotes: 'Release notes',
        resetTutorial: 'Reset tutorial',
        status: 'Service status',
        welcomeStepper: 'Launch presentation',
        roadmap: 'Roadmap',
      },
      es: {
        apiDocumentation: 'Documentación de las API',
        productDocumentation: 'Documentación del producto',
        needHelp: '¿Necesita ayuda?',
        organizations: 'Organizaciones',
        seeAllReleases: 'Ver todos los lanzamientos',
        stackcraftStepper: 'Comenzar presentación de StackCraft',
        launchTutorial: 'Iniciar tour',
        releaseNotes: 'Notas de lanzamiento',
        resetTutorial: 'Restablecer tutorial',
        status: 'Estado del servicio',
        welcomeStepper: 'Comenzar presentación',
        roadmap: 'Hoja de ruta',
      },
      fr: {
        apiDocumentation: `Documentation de l'API`,
        productDocumentation: 'Documentation produit',
        needHelp: `Besoin d'aide`,
        organizations: 'Organisations',
        seeAllReleases: 'Voir toutes les versions',
        stackcraftStepper: 'Lancer la présentation de StackCraft',
        launchTutorial: 'Lancer la visite guidée',
        releaseNotes: 'Notes de version',
        resetTutorial: 'Tutoriel de réinitialisation',
        status: 'État du service',
        welcomeStepper: 'Lancer la présentation',
        roadmap: 'Feuille de route',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-list-item {
  min-height: 40px !important;

  &__action {
    margin: 0 9px 0 0 !important;

    .v-icon {
      max-width: 20px;
      max-height: 20px;
    }
  }

  &__title {
    display: flex;
    align-items: center;

    &--error {
      color: get-color("error");
    }

    .v-icon {
      margin-right: 8px;
    }
  }
}

.cy-menu {
  ::v-deep .v-list-item--active .v-list-item__title {
    font-weight: 600;
  }

  &__item {
    min-height: 56px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.bottom-links {
  padding: 8px 16px;
  color: get-color("primary", "light-2");
  font-size: map.get($font-sizes, "sm");
}
</style>
