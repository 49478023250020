<template>
  <v-toolbar
    class="cy-toolbar"
    height="44"
    flat>
    <CyButton
      v-show="isMobile"
      variant="tertiary"
      theme="primary"
      icon-only
      icon="menu"
      @click="TOGGLE_SIDEBAR_VISIBILITY"/>

    <div class="d-flex flex-grow-1">
      <CyToolbarOrgSettingsBreadcrumbs class="flex-grow-0"/>

      <CyToolbarBreadcrumbs/>
    </div>

    <CyToolbarTrialReminder/>

    <CyTooltip
      v-if="isInCustomerScope"
      z-index="111"
      bottom>
      <template #activator="{ on }">
        <div
          class="org-menu__wrapper"
          v-on="on">
          <CyToolbarOrgMenu/>
        </div>
      </template>

      <span>{{ tooltipText }}</span>
    </CyTooltip>

    <CyToolbarOrgMenu v-else/>

    <CySecondaryNav/>
  </v-toolbar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import CyToolbarBreadcrumbs from '@/components/toolbar/breadcrumbs'
import CyToolbarOrgSettingsBreadcrumbs from '@/components/toolbar/org-settings-breadcrumbs'
import CySecondaryNav from '@/components/secondary-nav'
import CyToolbarTrialReminder from '@/components/toolbar/trial-reminder'
import CyToolbarOrgMenu from '@/components/toolbar/org-menu'

export default {
  name: 'CyToolbar',
  components: {
    CyToolbarBreadcrumbs,
    CyToolbarOrgSettingsBreadcrumbs,
    CyToolbarTrialReminder,
    CySecondaryNav,
    CyToolbarOrgMenu,
  },
  computed: {
    ...mapGetters('customers', [
      'currentOrgScope',
      'isInCustomerScope',
    ]),
    tooltipText () {
      return `${this.$t('loggedInVia')} ${this.currentOrgScope.fullPath}`
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs
    },
  },
  methods: {
    ...mapMutations('layout', [
      'TOGGLE_SIDEBAR_VISIBILITY',
    ]),
  },
  i18n: {
    messages: {
      en: {
        loggedInVia: 'Logged in via',
      },
      es: {
        loggedInVia: 'Inició sesión a través de',
      },
      fr: {
        loggedInVia: 'Connecté via',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$content-border-radius: 32px;

.org-menu__wrapper {
  border: 1px dashed get-color("primary", "light-2");
  border-radius: 2px;
}

.cy-toolbar {
  top: 0;
  flex-grow: 0;
  z-index: 108;
  transition: none;

  .v-toolbar__title {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }

  ::v-deep {
    .breadcrumbs-container {
      overflow: hidden;

      .breadcrumb__item,
      .current-breadcrumb {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .v-toolbar__content {
      padding: 0 32px;
    }
  }
}
</style>
