<template>
  <div class="trial-reminder px-4">
    <CyTooltip
      :disabled="trialExpired"
      bottom>
      <template #activator="{ on }">
        <span v-on="on">
          <router-link :to="{ name: 'orgSettings', params: { orgCanonical } }">
            <CyTag
              v-if="isFreeTrial || trialExpired"
              :class="[trialExpired ? 'trial-tag--error' : 'trial-tag--default']">
              <span
                v-if="trialExpired"
                class="trial-reminder__text">
                {{ $t('trialExpired') }}
              </span>
              <span
                v-else
                class="trial-reminder__text">
                {{ $t('untranslated.freeTrial') }}
              </span>
            </CyTag>

            <CyLicenceProgressBar
              v-if="!trialExpired"
              sm/>

          </router-link>
        </span>
      </template>
      <span>{{ tooltipText }}</span>
    </CyTooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CyLicenceProgressBar from '@/components/licence-progress-bar.vue'

export default {
  name: 'CyToolbarTrialReminder',
  components: {
    CyLicenceProgressBar,
  },
  computed: {
    ...mapGetters('organization/billing', [
      'daysRemaining',
      'hoursRemaining',
      'trialExpired',
      'isFreeTrial',
    ]),
    tooltipText () {
      const { daysRemaining, hoursRemaining } = this

      if (daysRemaining <= 0 && hoursRemaining >= 0) {
        if (hoursRemaining > 0) return this.$tc('hoursRemaining', hoursRemaining, { hours: hoursRemaining })
        if (hoursRemaining === 0) return this.$t('lessThanOneHourLeft')
      }

      return this.$tc('daysRemaining', daysRemaining, { days: daysRemaining })
    },
  },
  i18n: {
    messages: {
      en: {
        daysRemaining: '{days} day remaining | {days} days remaining',
        hoursRemaining: '{hours} hour remaining | {hours} hours remaining',
        lessThanOneHourLeft: 'Less than one hour left',
        trialExpired: 'Trial expired',
      },
      es: {
        daysRemaining: '{days} día restante | {days} días restantes',
        hoursRemaining: '{hours} hora restante | {hours} horas restantes',
        lessThanOneHourLeft: 'Menos de una hora restante',
        trialExpired: 'Prueba vencida',
      },
      fr: {
        daysRemaining: '{days} jour restant | {days} jours restants',
        hoursRemaining: '{hours} heure restant | {hours} heures restants',
        lessThanOneHourLeft: `Il reste moins d'une heure`,
        trialExpired: 'Essai expiré',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.trial-reminder {
  margin-right: 0;
  margin-left: auto;

  .trial-tag {
    padding-bottom: 2px;

    &--default {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background-color: get-color("grey", "light-2");
      color: get-color("primary");
    }

    &--error {
      background-color: get-color("error");
      color: get-color("background");
    }
  }

  &__text {
    padding-right: 6px;
    padding-left: 6px;
  }
}

::v-deep .licence-progress-bar {
  margin: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
