<template>
  <div
    v-if="$showDevThings"
    v-clickaway="() => $toggle.show.switcher(false)"
    :class="['dev-locale-switcher', { 'sidebar-is-collapsed': isSidebarCollapsed }]">
    <CyButton
      theme="grey"
      variant="tertiary"
      class="dev-locale-switcher__btn"
      @click.native="$toggle.show.switcher">
      <CyTooltip
        :disabled="!isSidebarCollapsed"
        right>
        <template #activator="{ on }">
          <img
            :src="currentLocale.icon"
            :alt="`${currentLocale.name} icon`"
            class="dev-locale-switcher__btn-image"
            v-on="on">
        </template>
        🦄 Switch Locale
      </CyTooltip>
      <span
        v-if="!isSidebarCollapsed"
        class="ml-2 dev-locale-switcher__btn-text">
        🦄 Switch Locale
      </span>
    </CyButton>
    <div
      v-if="show.switcher"
      class="dev-locale-switcher__options">
      <section
        v-for="{ locale, name, icon } in otherLocales"
        :key="locale"
        class="option"
        @click.stop="switchLocale(locale)">
        <img
          :src="icon"
          :alt="`${name} icon`"
          class="option__image">
        <span class="option__name">{{ name }}</span>
      </section>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'CyDevLocaleSwitcher',
  props: {
    isSidebarCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    show: {
      switcher: false,
    },
  }),
  computed: {
    ...mapGetters('i18n', [
      'localesList',
    ]),
    currentLocale () {
      return this.localesList.find(({ locale }) => locale === this.$i18n.locale)
    },
    otherLocales () {
      return this.localesList.filter(({ locale }) => locale !== this.$i18n.locale)
    },
  },
  methods: {
    ...mapMutations('i18n', [
      'CHANGE_LOCALE',
    ]),
    switchLocale (locale) {
      this.CHANGE_LOCALE(locale)
      this.$toggle.show.switcher(false)
    },
  },
}
</script>

<style lang="scss" scoped>
  %flag-icon {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .dev-locale-switcher {
    display: flex;
    position: relative;
    flex: 0 1 auto;
    justify-content: center;
    width: 100%;
    border: none;

    &__btn.cy-btn.v-btn.v-btn {
      @extend %flag-icon;

      z-index: 5;
      flex: 0 1 auto;
      justify-content: flex-start;
      width: 100%;
      height: 40px;
      padding: 6px;
      border: none;
      border-radius: 8px;
      box-shadow: none;

      &:focus {
        outline: none;
      }

      &.cy-btn__variant--tertiary:hover {
        @extend %rainbow-background;
      }
    }

    &__btn {
      color: var(--menu-text-color) !important;
    }

    &__options {
      display: flex;
      position: absolute;
      z-index: 4;
      top: -7em;
      flex-direction: column;
      min-width: 60px;
      padding: 0.5em 0;
      border-radius: 0.25em;
      background: get-color("black", $alpha: 0.5);

      .option {
        display: flex;
        align-content: center;
        align-items: center;
        padding: 0 1em;

        @extend %clickable;

        &__image {
          padding-right: 1em;
        }

        &__name {
          color: white;
          font-size: 0.9rem;
        }

        &:hover .option__name {
          color: get-color("accent");
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -2em;
        align-self: center;
        width: 1em;
        height: 1em;
        border: 1em solid transparent;
        border-top: 1em solid get-color("black", $alpha: 0.5);
      }
    }

    &.sidebar-is-collapsed {
      .dev-locale-switcher {
        &__btn.cy-btn.v-btn.v-btn {
          justify-content: center;
          width: 40px;
        }

        &__options {
          top: -9.5em;
        }
      }

      .option {
        flex-direction: column;
        justify-content: center;
        padding: 0;

        &__image {
          padding: 0;
        }

        &__name {
          margin-top: -0.5em;
          margin-bottom: 0.5em;
        }

        &:last-of-type {
          .option__name {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>
